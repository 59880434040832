import React from 'react';
import {Stack} from "@mui/joy";

// components
import CommonInfoTab from "../Shared/CommonInfoTab";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import InfoIcon from "@mui/icons-material/Info";


class WebinarTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'WebinarViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        await this.setState(
            {
                updatePermission: p
            }
        );
    }

    render() {
        return(
            <Stack
                spacing={2}
            >
                <CommonInfoTab
                    callBack={this.props.callBack}
                    groups={
                        [
                            {
                                title: 'Общая информация',
                                icon: <InfoIcon/>,
                                fields: [
                                    {
                                        title: 'Название',
                                        value: this.props.webinar.title,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['title', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'Тэги',
                                        value: this.props.webinar.tags,
                                        fieldFromArray: 'title',
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: [],
                                            m2mFields: ['tags', ],
                                            baseUrl: 'webinar',
                                            obj: {
                                                id: this.props.webinar.id,
                                                tags: this.props.webinar.tags.map((item) => (item.id))
                                            }
                                        }
                                    },
                                    {
                                        title: 'Докладчики',
                                        value: this.props.webinar.speakers,
                                        fieldFromArray: 'last_name',
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: [],
                                            m2mFields: ['speakers', ],
                                            baseUrl: 'webinar',
                                            obj: {
                                                id: this.props.webinar.id,
                                                speakers: this.props.webinar.speakers.map((item) => (item.id))
                                            }
                                        }
                                    },
                                    {
                                        title: 'Модераторы',
                                        value: this.props.webinar.moderators,
                                        fieldFromArray: 'last_name',
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: [],
                                            m2mFields: ['moderators', ],
                                            baseUrl: 'webinar',
                                            obj: {
                                                id: this.props.webinar.id,
                                                moderators: this.props.webinar.moderators.map((item) => (item.id))
                                            }
                                        }
                                    },
                                    {
                                        title: 'Окончен',
                                        value: this.props.webinar.ended,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['ended', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'Опубликован',
                                        value: this.props.webinar.published,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['published', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'ON AIR',
                                        value: this.props.webinar.onair,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['onair', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'Продолжительность',
                                        value: this.props.webinar.duration,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['vk_id', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'VK ID',
                                        value: this.props.webinar.vk_id,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['vk_id', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'VK OID',
                                        value: this.props.webinar.vk_oid,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['vk_oid', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'VK HASH',
                                        value: this.props.webinar.vk_hash,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['vk_hash', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Описания',
                                icon: <InfoIcon/>,
                                fields: [
                                    {
                                        title: 'Краткое описание',
                                        value: this.props.webinar.short_description,
                                        html: true,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['short_description', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                    {
                                        title: 'Полное описание',
                                        value: this.props.webinar.description,
                                        html: true,
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['description', ],
                                            baseUrl: 'webinar',
                                            obj: this.props.webinar
                                        }
                                    },
                                ]
                            }
                        ]
                    }
                />
            </Stack>
        )
    }
}

export default WebinarTab