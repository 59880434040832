import React from "react";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import {CircularProgress} from "@mui/joy";


class MarketingUserVisitTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: null,
            pages: null,
            page: 1
        };
    }

    async componentDidMount() {
        await this.retrieveStats();
    }

    async retrieveStats(page=1) {
        await this.setState(
            {
                stats: null
            }
        );
        const url = `stat/`;
        const params = {
            user: this.props.user.id,
            page: page,
        }
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        await this.setState(
            {
                stats: response.data.results,
                pages: response.data.total_pages,
                page: page
            }
        );
    }

    render() {
        if (!this.state.stats) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <CommonPaginatedTable
                data={this.state.stats}
                pages={this.state.pages}
                pageNumber={this.state.page}
                pagesCallback={this.retrieveStats.bind(this)}
                fields={[
                    {
                        title: 'Визит',
                        name: 'visit.id',
                        visible: true,
                    },
                    {
                        title: 'URL',
                        name: 'url',
                        visible: true,
                    },
                    {
                        title: 'Бразузер',
                        name: 'browser.name',
                        visible: true,
                    },
                    {
                        title: 'ОС',
                        name: 'os.name',
                        visible: true,
                    },
                    {
                        title: 'IP',
                        name: 'ip',
                        visible: true,
                    },
                    {
                        title: 'Продолжительность',
                        name: 'duration',
                        visible: true,
                    },
                    {
                        title: '',
                        name: 'created_on',
                        visible: true,
                    },
                ]
                }
            />
        )
    }
}

export default MarketingUserVisitTab
