import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import RenderIfVisible from 'react-render-if-visible'
import List from "@mui/joy/List";
import { CircularProgress } from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommentItem from "./CommentItem";

// context
import UserSubscriberContext from "../../context/UserSubscriberContext";


class CommentList extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            favComments: null,
            comments: null,
        };
    }

    async componentDidMount() {
        await this.retrieveComments();
        await this.retrieveFavComments();

        let token = JSON.parse(localStorage.getItem("authTokens")).access;
        this.client = new W3CWebSocket(
            `${process.env.REACT_APP_WS_HOST}/ws/comment/${this.props.appName}/${this.props.appId}/?token=${token}`
        );
        this.client.onopen = () => {
            console.log("WebSocket Client Connected");
        };
        this.client.onclose = () => {
            console.log("WebSocket Client Disconnected");
        }
        this.client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
                this.setState((state) => ({
                    comments: [
                        {
                            id: dataFromServer.id,
                        },
                        ...state.comments,
                    ],
                }));
            }
        };
        this.timer = setInterval(() => (this.client.send(JSON.stringify({type: "ping"}))), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    async retrieveLists() {
        await this.retrieveComments();
        await this.retrieveFavComments();
    }

    async retrieveFavComments(){
        const url = 'comment/';
        const params = {
            related_app_id: this.props.appId,
            related_app: this.props.appName,
            deleted_bool: 0,
            favorite: 1,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                favComments: response.data.results
            }
        );
    }

    async retrieveComments(){
        const url = 'comment/';
        const params = {
            related_app_id: this.props.appId,
            related_app: this.props.appName,
            deleted_bool: 0,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                comments: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.comments) {
            return (<CircularProgress />)
        }
        if (!this.state.favComments) {
            return (<CircularProgress />)
        }
        return(
            <List>
                {
                    this.state.favComments.map(each=>{
                        return (
                            <RenderIfVisible
                                defaultHeight={200}
                                stayRendered={true}
                                key={each.id}
                            >
                                <CommentItem
                                    comment={each}
                                    favorite={true}
                                    user={this.context.userObject}
                                    callBack={this.retrieveLists.bind(this)}
                                />
                            </RenderIfVisible>
                        )
                    })
                }
                {
                    this.state.comments.map(each=>{
                        return (
                            <RenderIfVisible
                                defaultHeight={200}
                                stayRendered={true}
                                key={each.id}
                            >
                                <CommentItem
                                    comment={each}
                                    callBack={this.retrieveLists.bind(this)}
                                />
                            </RenderIfVisible>
                        )
                    })
                }
            </List>
        )
    }
}

export default CommentList;
