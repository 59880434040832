import React from 'react';
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import WebinarCreateModal from "./WebinarCreateModal";


class WebinarList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webinars: [],
            pages: null,
            filters: {},
        };
    }

    async componentDidMount() {
        await this.retrieveWebinars();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveWebinars();
        }
    }

    async filterCallBack(filters){
        await this.setState({
            filters: filters
        });
        await this.retrieveWebinars();
    }

    async retrieveWebinars() {
        this.setState(
            {
                webinars: null,
            }
        );
        const url = 'webinar/';
        const api = new BaseApi();
        let params = {};
        params = {...params, ...this.state.filters};
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                webinars: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.webinars) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Вебинары'}
                    url={'admin/webinar'}
                    createComponent = {WebinarCreateModal}
                    createComponentProps={{}}
                    callBack={this.retrieveWebinars.bind(this)}
                    // filterFields={[
                    //     {
                    //             name: 'work_status',
                    //             label: 'Статус',
                    //             xs: 12,
                    //             sm: null,
                    //             md: null,
                    //             lg: null,
                    //             xl: null,
                    //             required: true,
                    //             default: null,
                    //             type: 'select',
                    //             options: [
                    //                 {value: 'PSD', label: 'На рассмотрении'},
                    //                 {value: 'WRK', label: 'В работе'},
                    //                 {value: 'COM', label: 'Завершена'},
                    //                 {value: 'CLD', label: 'Отклонена'},
                    //             ]
                    //         },
                    // ]}
                    // filterCallBack={this.filterCallBack.bind(this)}
                    data={this.state.webinars}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    fields={[
                        {
                            title: 'Заголовок',
                            name: 'title',
                            visible: true,
                            linkPattern: '/admin/webinar/detail/:linkParam',
                            linkParam: 'id'
                        },
                        {
                            title: 'Продолжительность',
                            name: 'duration',
                            visible: true,
                        },
                        {
                            title: 'Дата начала',
                            name: 'start_dt',
                            visible: true,
                            // chip: true,
                            // color_rule: (value) => (value),
                            // color_value: 'work_status_color',
                        },
                        {
                            title: 'Зарегистрировано',
                            name: 'attendees.length',
                            visible: true,
                            chip: true,
                            color_rule: (value, comparison) => (value>0?'success':'error'),
                            color_value: 'attendees.length',
                        },
                        {
                            title: 'Тэги',
                            name: 'tags.length',
                            visible: true,
                            chip: true,
                            color_rule: (value, comparison) => (value>0?'success':'error'),
                            color_value: 'tags.length',
                        },
                        {
                            title: 'Окончен',
                            name: 'ended',
                            visible: true,
                            // chip: true,
                            // color_rule: (value) => (value),
                            // color_value: 'work_status_color',
                        },
                        {
                            title: 'Опубликован',
                            name: 'published',
                            visible: true,
                            // chip: true,
                            // color_rule: (value) => (value),
                            // color_value: 'work_status_color',
                        },
                        {
                            title: 'Создано',
                            name: 'created_on',
                            visible: true,
                        },
                        {
                            title: 'Обновлено',
                            name: 'updated_on',
                            visible: true,
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(WebinarList)