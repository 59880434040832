import axios from "axios";
import {jwtDecode} from "jwt-decode";
import dayjs from "dayjs";
import * as qs from 'qs'


class BaseApi {
    constructor() {
        this.baseHost = process.env.REACT_APP_API_HOST;
        this.baseUrl = `${this.baseHost}/api/v2/`;
        const instance = axios.create();
        instance.interceptors.request.use(
            (config) => {
                config._retry = false;
                let authTokens = localStorage.getItem("authTokens")
                    ? JSON.parse(localStorage.getItem("authTokens")) : null;
                if (authTokens !== null) {
                    const user = jwtDecode(authTokens.access);
                    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 5;
                    if (!isExpired) {
                        config.headers["Authorization"] = `Bearer ${authTokens.access}`;
                    }
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
        instance.interceptors.response.use(this.handleSuccess, this.handleError.bind(this));

        this.instance = instance;
    }

    handleSuccess(response) {
        return response;
    }

    handleError(error) {
        const config = error.config;
        const configUrl = config.url;
        const retryCondition = [
            !configUrl.includes("auth/token/"),
            !config._retry,
            error.response.status === 401,
        ].every(el => !!el);
        if (retryCondition) {
            config._retry = true;
            let authTokens = localStorage.getItem("authTokens")
                ? JSON.parse(localStorage.getItem("authTokens")) : null;
            if (authTokens) {
                axios.post(`${this.baseUrl}auth/token/refresh/`, {refresh: authTokens.refresh})
                    .then((response) => {
                        authTokens.access = response.data.access;
                        authTokens.refresh = response.data.refresh;
                        localStorage.setItem('authTokens', JSON.stringify(authTokens));
                        config.headers.Authorization = `Bearer ${response.data.access}`;
                        return axios(config)
                    })
                    .catch((error) => {
                        console.error(error);
                        localStorage.removeItem('authTokens');
                        window.location.replace("/login");
                        return error.response;
                    })
            } else {
                window.location.replace("/login");
            }
        } else {
            return error.response;
        }
    }

    async get(url, params) {
        return await this.instance.get(`${this.baseUrl}${url}`, {
            params: params,
            paramsSerializer: params => qs.stringify(params)
        })
    }

    async post(url, data, multipart = false) {
        const config = multipart ? {headers: {'Content-Type': 'multipart/form-data'}} : {};
        return await this.instance.post(`${this.baseUrl}${url}`, data, config);
    }

    async patch(url, data, multipart = false) {
        const config = multipart ? {headers: {'Content-Type': 'multipart/form-data'}} : {};
        return await this.instance.patch(`${this.baseUrl}${url}`, data, config);
    }

    async delete(url) {
        return await this.instance.delete(`${this.baseUrl}${url}`);
    }
}

export default BaseApi