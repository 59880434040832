import React from 'react';
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import {CardOverflow, Stack,} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {Handle, Position} from "reactflow";
import Chip from "@mui/material/Chip";

// components
import BaseApi from "../../utils/BaseApi";
import IdeaSolve from "./IdeaSolve";
import IdeaSetOwner from "./IdeaSetOwner";
import CommonEditModal from "../Shared/CommonEditModal";
import IdeaCreate from "./IdeaCreate";
import TaskCard from "../Task/TaskCard";

// icons
import OpenWithIcon from '@mui/icons-material/OpenWith';
import IconButton from "@mui/joy/IconButton";


class IdeaNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ideaNode: props.data.obj,
        };
    }

    async componentDidMount() {
        // await this.retrieveIdeaNode()
    }

    async retrieveIdeaNode() {
        const url = `idea_node/${this.state.ideaNode.id}/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                ideaNode: response.data
            }
        )
    }

    async editCallBack(){
        await this.retrieveIdeaNode();
        await this.props.data.callBack();
    }

    render() {
        return (
            <>
                <Card
                    variant={'soft'}
                    sx={{
                        width: '300px'
                    }}
                >
                    <CardOverflow
                        // variant={"soft"}
                    >
                        <CardContent>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Chip
                                    level="body-md"
                                    color={this.state.ideaNode.real_color}
                                    label={this.state.ideaNode.title}
                                >
                                </Chip>
                                <CommonEditModal
                                    fields={
                                        [
                                            'title',
                                            'description',
                                            'color'
                                        ]
                                    }
                                    baseUrl={'idea_node'}
                                    obj={this.state.ideaNode}
                                    callBack={this.editCallBack.bind(this)}
                                />
                                <IconButton
                                    className="custom-drag-handle"
                                >
                                    <OpenWithIcon/>
                                </IconButton>
                            </Stack>
                        </CardContent >
                    </CardOverflow>
                    <CardContent>
                        <Typography level="body-md">
                            {this.state.ideaNode.description}
                        </Typography>
                        {
                            this.state.ideaNode.owner?
                                <>
                                    <Typography level="body-md">
                                        Ответственный: {this.state.ideaNode.owner.user.first_name} {this.state.ideaNode.owner.user.last_name}
                                    </Typography>
                                    <CommonEditModal
                                        fields={
                                            [
                                                'owner',
                                            ]
                                        }
                                        baseUrl={'idea_node'}
                                        obj={this.state.ideaNode}
                                        callBack={this.editCallBack.bind(this)}
                                    />
                                </>:
                                null
                        }
                        {
                            this.state.ideaNode.decision === 'WAIT' && !this.state.ideaNode.owner?
                                <IdeaSetOwner
                                    ideaNode={this.state.ideaNode}
                                    callBack={this.props.data.callBack}
                                />:
                                null
                        }
                        {
                            this.state.ideaNode.decision === 'WAIT' && this.state.ideaNode.owner?
                                <IdeaSolve
                                    ideaNode={this.state.ideaNode}
                                    callBack={this.props.data.callBack}
                                />:
                                null
                        }
                        {
                            this.state.ideaNode.decision === 'BRANCH' && this.state.ideaNode.owner?
                                <IdeaCreate
                                    parent={this.state.ideaNode.id}
                                    x={this.state.ideaNode.x_coordinate}
                                    y={this.state.ideaNode.y_coordinate}
                                    callBack={this.props.data.callBack}
                                    title={'Добавить ветку'}
                                />:
                                null
                        }
                        {
                            this.state.ideaNode.task?
                                <TaskCard
                                    task={this.state.ideaNode.task}
                                    // callBack={this.props.data.callBack}
                                />:
                                null
                        }
                        {
                            this.state.ideaNode.decision === 'END' ?
                                <Chip
                                    color={'success'}
                                    label={'Завершено'}
                                />:
                                null
                        }
                    </CardContent>
                </Card>
                <Handle
                    type="target"
                    // isConnectable={this.props.isConnectable}
                    position={Position.Left}
                    style={{
                        background: "green",
                        width: 0,
                        height: 0,
                    }}
                />
                <Handle
                    type="source"
                    // isConnectable={this.props.isConnectable}
                    position={Position.Right}
                    style={{
                        background: "red",
                        width: 0,
                        height: 0,
                    }}
                    id="blue"
                />
            </>
        )
    }
}

export default IdeaNode;
