import React from "react";

import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";

// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";

// icons
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import dayjs from "dayjs";

class IdeaSolve extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: [
                {
                    name: 'decision',
                    label: 'Решение',
                    xs: 12,
                    sm: null,
                    md: null,
                    lg: null,
                    xl: null,
                    required: true,
                    default: null,
                    type: 'select',
                    options: [
                        {value: 'END', label: 'Завершить'},
                        {value: 'TASK', label: 'Конвертировать в задачу'},
                        {value: 'BRANCH', label: 'Добавить ветвление'},
                    ]
                },
                {
                    type: 'submit',
                    label: 'Применить',
                },
            ],
            success: false,
        };
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async createTask(){
        const api = new BaseApi();
        const url = 'task/'
        const today = new Date();
        const data = {
            title: this.props.ideaNode.title,
            start_dt: dayjs(today).format('YYYY-MM-DD'),
            end_dt: dayjs(today).format('YYYY-MM-DD'),
            duration_plan: 0,
            desc: this.props.ideaNode.description,
            important_bool:true,
            account: 1,
            executor: this.props.ideaNode.owner.user.id,
        };
        let response = await api.post(
            url,
            data
        );
        return response.data.id
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        if (formDataObj.decision === 'TASK'){
            formDataObj.task = await this.createTask();
            formDataObj.decision = 'END'
        }
        const url = `idea_node/${this.props.ideaNode.id}/`;
        const api = new BaseApi();
        await api.patch(
            url,
            formDataObj
        );
        await this.setModalOpenFalse();
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    render() {
        if (!this.state.formFields){
            return (<CircularProgress/>)
        }
        return (
            <Box>
                <Button
                    size={'sm'}
                    color="warning"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <LightbulbIcon size={'sm'}/> Принять решение
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Принятие решения
                            </Typography>
                            <CommonForm
                                fields={this.state.formFields}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default IdeaSolve
