import React from 'react';

import withNavigate from "../../utils/withNavigate";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import InlineDeliveryStatsBars from "./InlineDeliveryStatsBars";


class DeliveryStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: null,
        };
    }

    async componentDidMount() {
        await this.retrieveStats();
    }

    async retrieveStats() {
        const url = `delivery_stage/stats/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                stats: response.data,
            }
        );
    }

    render() {
        if (!this.state.stats) {
            return (<CircularProgress/>)
        }
        return (
            <CommonPaginatedTable
                title={null}
                data={this.state.stats.stages}
                fields={[
                    {
                        title: 'Бизнес-процесс',
                        name: 'stage.xtype_human',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                    {
                        title: 'Стадия',
                        name: 'stage.title',
                        visible: true,
                        chip: true,
                        color_value: 'stage.color',
                        color_rule: (value, comparison) => (value),
                    },
                    {
                        title: 'Пройдено',
                        name: 'length',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                    {
                        title: '',
                        visible: true,
                        use_component: true,
                        component: InlineDeliveryStatsBars,
                        // callBack: this.props.callBack
                    },

                    {
                        title: 'Норма',
                        name: 'stage.end_delay',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                    {
                        title: 'Минимум',
                        name: 'minimum',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                    {
                        title: 'Максимум',
                        name: 'maximum',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value>comparison?'danger':'success'),
                        color_value: 'maximum',
                        color_comparison: 'stage.end_delay'
                    },
                    {
                        title: 'Среднее',
                        name: 'mean',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value>comparison?'danger':'success'),
                        color_value: 'mean',
                        color_comparison: 'stage.end_delay',
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Медиана',
                        name: 'median',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value>comparison?'danger':'success'),
                        color_value: 'median',
                        color_comparison: 'stage.end_delay',
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Мода',
                        name: 'mode',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => (value>comparison?'danger':'success'),
                        color_value: 'mode',
                        color_comparison: 'stage.end_delay',
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                ]}
            />
        )
    }
}

export default withNavigate(DeliveryStats);
