import React from 'react';
import CommonSidebar from "../Shared/CommonSidebar";

// components
import BaseApi from "../../utils/BaseApi";

// icons
import LinkIcon from '@mui/icons-material/Link';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import InfoIcon from '@mui/icons-material/Info';
import FolderIcon from '@mui/icons-material/Folder';
import AttachFileIcon from '@mui/icons-material/AttachFile';


class HomeSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            links: null,
        };
    }

    async componentDidMount() {
        await this.retrieveLinks();
    }

    getIcon(s) {
        let icons = {
            presentation: <CoPresentIcon />,
            folder: <FolderIcon />,
            wiki: <InfoIcon />,
            link: <LinkIcon />,
        };
        if (s in icons){
           return icons[s]
        }
        return <AttachFileIcon/>
    }

    async retrieveLinks() {
        const url = 'nextgen_main_links/';
        const api = new BaseApi();
        let response = await api.get(url, {});
        let links = response.data.results.map((link) => (
            {
                title: link.title,
                icon: this.getIcon(link.icon),
                component: link.blank?'link':'default',
                link: link.link,
            }
        ));

        await this.setState(
            {
                links: links
            }
        );
    }

    render() {
        if (!this.state.links) {
            return (<></>)
        }
        return(
            <CommonSidebar
                groups={
                    [
                        {
                            title: 'Полезные ссылки',
                            items: this.state.links,
                        }
                    ]
                }
            />
        )
    }
}

export default HomeSidebar;
