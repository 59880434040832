import React from "react";
import BaseApi from "../../utils/BaseApi";
import {Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';

// components
import CommonForm from "../Shared/CommonForm";


class WebinarCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
        };
    }

    async retrieveFields(){
        const url = `webinar/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return(response.data)
    }

    async setModalOpenTrue(){
        let formFields = await this.retrieveFields();
        await this.setState({
            modalOpen: true,
            formFields: formFields,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        if ('tags' in formDataObj) {
            if (formDataObj.tags === '') {
                formDataObj.tags = [];
            }
            else{
                formDataObj.tags = formDataObj.tags.split(',');
            }
        }
        if ('speakers' in formDataObj) {
            if (formDataObj.speakers === '') {
                formDataObj.speakers = [];
            }
            else{
                formDataObj.speakers = formDataObj.speakers.split(',');
            }
        }
        const url = `webinar/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            this.setModalOpenFalse();
        }
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        this.props.callBack();
    }

    render() {
        return (
            <Box>
                <Button
                    size={'sm'}
                    color="success"
                    sx={{mt: 2, mr: 2}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            {
                                this.state.open?
                                    <>
                                        <Typography>
                                           Вебинар добавлен успешно!
                                        </Typography>
                                        <Button
                                            color="success"
                                            sx={{mt: 2}}
                                            onClick={this.setModalOpenFalse.bind(this)}
                                        >
                                            Закрыть вкладку
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Typography
                                            level={'h2'}
                                            sx={{mb: 2}}
                                        >
                                            Вебинар
                                        </Typography>
                                        <CommonForm
                                            fields={this.state.formFields}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </>
                            }
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default WebinarCreateModal
