import React from 'react';

// components
import CommonSidebar from "../Shared/CommonSidebar";
import TaskCreateModal from "../Task/TaskCreateModal";

//icons
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArchiveIcon from '@mui/icons-material/Archive';
import ListIcon from "@mui/icons-material/List";


class TaskSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'Бизнес-процессы',
                        items: [
                            {
                                title: 'Мои',
                                link: '/delivery/',
                                icon: <ListIcon />,
                            },
                            {
                                title: 'Коллег',
                                link: '/delivery/subordinate/',
                                icon: <ListIcon />,
                            },
                        ],
                    },
                    {
                        title: 'Маршруты',
                        items: [
                            {
                                title: 'Мои',
                                link: '/route/',
                                icon: <ListIcon />,
                            },
                            {
                                title: 'Коллег',
                                link: '/route/subordinate/',
                                icon: <ListIcon />,
                            },
                        ],
                    },
                    {
                        title: 'Задачи',
                        items: [
                            {
                                type: 'component',
                                component: <TaskCreateModal
                                    account={null}
                                    opportunity={null}
                                    phase={null}
                                    activeStep={0}
                                    lockedSteps={[]}
                                />
                            },
                            {
                                title: 'Моя доска',
                                link: '/task/',
                                icon: <AssignmentIcon />,
                            },
                            {
                                title: 'Доска по коллегам',
                                link: '/task/owner/',
                                icon: <AssignmentIcon />,
                            },
                            {
                                title: 'Мой календарь',
                                link: '/task/mycalendar/',
                                icon: <CalendarMonthIcon />,
                            },
                            {
                                title: 'Мои завершенные',
                                link: '/task/completed/',
                                icon: <ArchiveIcon />,
                            },
                            {
                                title: 'Завершенные коллег',
                                link: '/task/ownercompleted/',
                                icon: <ArchiveIcon />,
                            },
                        ],
                    }
                    ]
                }
            />
        )
    }
}

export default TaskSidebar;