import BaseApi from "./BaseApi";

async function retrievePermission(viewset, action, subscriberObject){
    console.log(subscriberObject);
    const url = `api_permission/`;
    const api = new BaseApi();
    let subscriberTypes = subscriberObject.subscriber_type.map(each => {
        return(
            each.id
        )
    });
    let permission_count = 0;
    for (const stype of subscriberTypes){
        let params = {
            viewset: viewset,
            action: action,
            subscriber_type: stype
        };
        let response = await api.get(
            url,
            params
        );
        permission_count += response.data.count
    }
    return permission_count
}

export default retrievePermission