import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";

// components
import AttachmentsTab from "../Attachment/AttachmentsTab";
import TasksTab from "../Task/TasksTab";
import AccountTab from "./AccountTab";
import OpportunityTab from "../Opportunity/OpportunitiesTab";
import ContactsTab from "../Contact/ContactsTab";
import AccountDetailHeader from "./AccountDetailHeader";
import RequisitesTab from "./RequisitesTab";

// icons
import EngineeringIcon from '@mui/icons-material/Engineering';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import OpportunityDetailHeader from "../Opportunity/OpportunityDetailHeader";


class AccountDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
        };
    }

    async componentDidMount() {
        await this.retrieveAccount();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveAccount();
        }
    }

    async retrieveAccount() {
        await this.setState(
            {
                account: null
            }
        );
        const url = `account/${this.props.params.accountId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                account: response.data
            }
        );
    }

    render() {
        if (!this.state.account) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'Account'}
                appId={this.state.account.id}
                header={{
                    component: AccountDetailHeader,
                    componentProps: {
                        account: this.state.account
                    },
                    leftOverflow: {
                        icon: null,
                        title: 'Компания',
                        color: 'primary'
                    },

                }}
                defaultTabValue={'info'}
                tabComponents={[
                    {
                        title: 'Информация',
                        value: 'info',
                        icon: <AccountTreeIcon />,
                        component: <AccountTab
                            account={this.state.account}
                            callBack={this.retrieveAccount.bind(this)}
                        />,
                    },
                    {
                        title: 'Вложения',
                        value: 'attachment',
                        icon: <AttachFileIcon />,
                        component: <AttachmentsTab
                            appId={this.state.account.id}
                            appName={'Account'}
                        />
                    },
                    {
                        title: 'Контакты',
                        value: 'contact',
                        icon: <ContactPageIcon />,
                        component: <ContactsTab
                            account={this.state.account}
                        />
                    },
                    {
                        title: 'Сделки',
                        value: 'opportunity',
                        icon: <EngineeringIcon />,
                        component: <OpportunityTab
                            accountId={this.state.account.id}
                        />
                    },
                    {
                        title: 'Задачи',
                        value: 'task',
                        icon: <AssignmentIcon />,
                        component: <TasksTab
                            accountId={this.state.account.id}
                        />,
                    },
                    {
                        title: 'Реквизиты',
                        value: 'requisites',
                        icon: <AccountBalanceIcon />,
                        component: <RequisitesTab
                            account={this.state.account}
                        />,
                    },
                ]}
            />
        )
    }
}

export default withRouter(AccountDetail);
