import React from 'react';
import {Stack} from "@mui/joy";

// components
import CommonInfoTab from "../Shared/CommonInfoTab";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import InfoIcon from "@mui/icons-material/Info";


class MarketingUserTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'WebinarViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        await this.setState(
            {
                updatePermission: p
            }
        );
    }

    render() {
        return(
            <Stack
                spacing={2}
            >
                <CommonInfoTab
                    callBack={this.props.callBack}
                    groups={
                        [
                            {
                                title: 'Общая информация',
                                icon: <InfoIcon/>,
                                fields: [
                                    {
                                        title: 'Электронная почта',
                                        value: this.props.user.email,
                                        edit: {
                                            permission: true,
                                            fields: ['email', ],
                                            baseUrl: 'marketing_user',
                                            obj: this.props.user
                                        }
                                    },
                                    {
                                        title: 'Телефон',
                                        value: this.props.user.phone,
                                        edit: {
                                            permission: true,
                                            fields: ['phone', ],
                                            baseUrl: 'marketing_user',
                                            obj: this.props.user
                                        }
                                    },
                                    {
                                        title: 'Рассылка',
                                        value: this.props.user.mailing,

                                    },
                                    {
                                        title: 'Персональные данные',
                                        value: this.props.user.personal_data,
                                    },
                                ]
                            }
                        ]}
                />
            </Stack>
        )
    }
}

export default MarketingUserTab