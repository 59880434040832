import React from "react";

import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";

// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";

// icons
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


class IdeaSetOwner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            subscribers: null,
            success: false,
        };
    }

    async componentDidMount() {
        await this.retrieveSubscribers();
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async retrieveSubscribers() {
        const url = 'subscriber/';
        const params = {
            active: true,

        }
        const api = new BaseApi();
        let response = await api.get(
            url,
            params,
        );
        this.setState({
            subscribers: response.data.results,
        });
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        const url = `idea_node/${this.props.ideaNode.id}/`;
        const api = new BaseApi();
        await api.patch(
            url,
            formDataObj
        );
        await this.setModalOpenFalse();
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    render() {
        if (!this.state.subscribers){
            return (<CircularProgress/>)
        }
        return (
            <Box>
                <Button
                    size={'sm'}
                    color="danger"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <ManageAccountsIcon size={'sm'}/> Назначить ответственного
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Назначение ответственного
                            </Typography>
                            <CommonForm
                                fields={
                                    [
                                        {
                                            name: 'owner',
                                            label: 'Ответственный',
                                            xs: 12,
                                            sm: null,
                                            md: null,
                                            lg: null,
                                            xl: null,
                                            required: true,
                                            default: null,
                                            type: 'select',
                                            options: this.state.subscribers.map(
                                                (each) => (
                                                    {
                                                        value: each.id,
                                                        label: `${each.user.first_name} ${each.user.last_name}`
                                                    }
                                                )
                                            )
                                        },
                                        {
                                            type: 'submit',
                                            label: 'Применить',
                                        },
                                    ]
                                }
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default IdeaSetOwner
