import React from "react";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import {CircularProgress} from "@mui/joy";


class WebinarAttendeeTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            present: null,
        };
    }

    async componentDidMount() {
        await this.retrieveAttendees();
    }

    async retrieveAttendees() {
        await this.setState(
            {
                present: null
            }
        );
        const url = `webinar/${this.props.webinar.id}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                present: response.data.present,
            }
        );
    }

    render() {
        if (!this.state.present) {
            return (<CircularProgress/>)
        }
        return (
            <CommonPaginatedTable
                data={this.state.present}
                fields={[
                    {
                        title: 'Фамилия',
                        name: 'last_name',
                        visible: true,
                        linkPattern: '/admin/marketing_user/detail/:linkParam',
                        linkParam: 'id',
                    },
                    {
                        title: 'Имя',
                        name: 'first_name',
                        visible: true,
                    },
                    {
                        title: 'Отчество',
                        name: 'patronymic',
                        visible: true,
                    },
                    {
                        title: 'Компания',
                        name: 'account_title',
                        visible: true,
                    },
                    {
                        title: 'Должность',
                        name: 'role',
                        visible: true,
                    },
                    {
                        title: 'Эл. почта',
                        name: 'email',
                        visible: true,
                    },
                    {
                        title: 'Телефон',
                        name: 'phone',
                        visible: true,
                    },

                ]
                }
            />
        )
    }
}

export default WebinarAttendeeTab