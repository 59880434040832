import React from 'react';
import RenderIfVisible from 'react-render-if-visible'
import OpportunityCard from "./OpportunityCard";
import {CircularProgress, Stack} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";


class OpportunityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunities: null,
        }
    }

    async retrieveOpportunities() {
        const url = 'opportunity/report/';
        const params = {
            status: this.props.status,
            team: this.props.team.join(','),
            xtypes: this.props.xtypes.join(','),
            my_roles: this.props.myRoles,
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState({
            opportunities: response.data.report,
        });
    }

    async componentDidMount() {
        await this.retrieveOpportunities();
    }

    render() {
        if (!this.state.opportunities) {
            return (
                <CircularProgress/>
            )
        }
        return (
            <Stack>
                {this.state.opportunities.map(
                each => {
                    return (
                        <RenderIfVisible defaultHeight={400} stayRendered={true} key={each[0]}>
                            <OpportunityCard
                                opportunityID={each[0]}
                                color={each[2]}
                            />
                        </RenderIfVisible>
                    )
                })}
            </Stack>
        )
    }
}

export default OpportunityList;