import React from "react";
import Box from "@mui/joy/Box";
import {CircularProgress, Stack,} from "@mui/joy";
import {
    applyEdgeChanges,
    applyNodeChanges,
    Background, BaseEdge,
    Controls,
    MiniMap,
    ReactFlow,
} from "reactflow";


// components
import UserSubscriberContext from "../../context/UserSubscriberContext";
import BaseApi from "../../utils/BaseApi";
import SelfConnectingEdge from "../Admin/SelfConnectingEdge";
import IdeaNode from "./IdeaNode";
import IdeaCreate from "./IdeaCreate";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";

import 'reactflow/dist/style.css';


const nodeTypes = {
    mainNode: IdeaNode,
};
const edgeTypes = {
    selfconnecting: SelfConnectingEdge,
    baseEdge: BaseEdge,
};


class IdeaMap extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            edges: null,
            nodes: null,
            ideas: null,
            isLoading: true,
        };
    }

    async componentDidMount() {
        await this.retrieveIdeas();
        await setTimeout(() => {
          this.setState({isLoading: false});
        }, 2000);
    }

    async retrieveIdeas(){
        await this.setState(
            {
                ideas: null,
            }
        );
        const url = `idea_node/`;
        const api = new BaseApi();
        const params = {
            archive: false,
        };
        let response = await api.get(url, params);
        let rawNodes = response.data.results.map((node) => {
            return(
                {
                    id: `${node.id}`,
                    position: {
                        x: node.x_coordinate,
                        y: node.y_coordinate,
                    },
                    data: {
                        label: node.title,
                        obj: node,
                        callBack: this.retrieveIdeas.bind(this)
                    },
                    width: 300,
                    height: 300,
                    type: 'mainNode',
                    dragHandle: '.custom-drag-handle',
                }
            )
            }
        );
        let rawEdges = [];
        for (let node of response.data.results) {
            if (node.parent){
                rawEdges.push(
                    {
                        id: `${node.parent}-${node.id}`,
                        source: `${node.parent}`,
                        target: `${node.id}`,
                        style: { stroke: '#fff' },
                        markerEnd: {
                            type: "arrowclosed"
                        },
                        type: 'selfconnecting',
                    }
                )
            }

        }
        await this.setState(
            {
                nodes: rawNodes,
                edges: rawEdges,
                ideas: response.data.results,
            }
        );
    }

    async onNodesChange(changes) {
        if (changes){
            for (const change of changes){
                if (change.type === 'position' && change.dragging){
                    const url = `idea_node/${change.id}/`;
                    const api = new BaseApi();
                    const data = {
                        x_coordinate: Math.ceil(change.position.x),
                        y_coordinate: Math.ceil(change.position.y),
                    }
                    await api.patch(url, data);
                }
            }
        }
        this.setState(
            {
                nodes: applyNodeChanges(changes, this.state.nodes)
            }
        );
    }

    onEdgesChange(changes) {
        this.setState(
            {
                edges: applyEdgeChanges(changes, this.state.edges)
            }
        );
    }

    render() {
        if (!this.state.ideas){
            return (<CircularProgress/>)
        }
        if (!this.state.nodes || !this.state.edges || this.state.isLoading){
            return (<CircularProgress/>)
        }
        return(
            <Stack>
                <Box
                    sx={{
                        mt: 1,
                        mb: 1
                    }}
                >
                    <IdeaCreate
                        callBack={this.retrieveIdeas.bind(this)}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 800,
                        border: 2,
                        borderColor: '#FFF',
                    }}
                >
                    <ReactFlow
                        nodes={this.state.nodes}
                        edges={this.state.edges}
                        onNodesChange={this.onNodesChange.bind(this)}
                        onEdgesChange={this.onEdgesChange.bind(this)}
                        fitView
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                    >
                        <Background />
                        <MiniMap
                            pannable={true}
                        />
                        <Controls/>
                    </ReactFlow>
                </Box>
                <CommonPaginatedTable
                    title={'Идеи'}
                    data={
                        this.state.ideas.filter(
                            (each) => each.decision === 'WAIT'
                        )
                    }
                    fields={[
                        {
                            title: 'Заголовок',
                            name: 'title',
                            visible: true,
                        },
                        {
                            title: 'Ответственный',
                            name: 'owner.user.first_name',
                            visible: true,
                        },
                        {
                            title: '',
                            name: 'owner.user.last_name',
                            visible: true,
                        },
                    ]
                    }
                />
            </Stack>
        )
    }
}

export default IdeaMap;
