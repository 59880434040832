import React from 'react';

import {CardOverflow, CircularProgress, Stack} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import OpportunityPaymentCert from "./OpportunityPaymentCert";
import CommentItem from "../Comment/CommentItem";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Grid from "@mui/joy/Grid";
import DeliveryTable from "../Delivery/DeliveryTable";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import withNavigate from "../../utils/withNavigate";


class OpportunityCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunity: null,
            comments: null,
            deliveries: null,
            my_roles: null,
        }
    }

    async retrieveOpportunity() {
        const url = 'opportunity/' + this.props.opportunityID + '/card/';
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState({
            opportunity: response.data.opportunity,
            comments: response.data.comments,
            delivery_states: response.data.delivery_states,
            my_roles: response.data.my_roles,
        });
    }

    async componentDidMount() {
        await this.retrieveOpportunity();
    }

    render() {
        if (!this.state.opportunity) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={
                    {
                        'm': 2,
                    }
                }
            >
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6} spacing={2}>
                            <Stack

                            >
                                <Grid container spacing={2}>
                                    <Grid xs={12} md={4}>
                                        <Typography level={'body2'} sx={{mb: 1}}>Название:</Typography>
                                        <Link
                                            href={`/opportunity/detail/${this.state.opportunity.id}`}
                                            target={'_blank'}
                                        >
                                            {this.state.opportunity.short_name}
                                        </Link>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <Typography level={'body2'} sx={{mb: 1}}>Компания:</Typography>
                                        <Link
                                            href={`/account/detail/${this.state.opportunity.account.id}`}
                                            target={'_blank'}
                                        >
                                            {this.state.opportunity.account.nameshort}
                                        </Link>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <Typography level={'body2'} sx={{mb: 1}}>Дедлайн:</Typography>
                                        {dayjs(this.state.opportunity.project_date).format('DD.MM.YYYY')}
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <Typography level={'body2'} sx={{mb: 1}}>Руководители:</Typography>
                                        {this.state.opportunity.accountables.map(
                                            row => {
                                                return (
                                                    <Chip
                                                        color={'warning'}
                                                        label={row.subscriber.user.first_name + ' ' + row.subscriber.user.last_name}
                                                        key={'accountable-chip-'+row.id}
                                                    />
                                                )
                                            })}
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <Typography level={'body2'} sx={{mb: 1}}>Мои роли:</Typography>
                                        {this.state.my_roles.map(
                                            row => {
                                                return (
                                                    <Chip
                                                        color={row.color}
                                                        label={row.role_human.slice(0, 3)}
                                                        sx={{mr: 1, mb: 1}}
                                                        key={'my-role-chip-'+row.id}
                                                    />
                                                )
                                            })}
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <OpportunityPaymentCert opportunityID={this.props.opportunityID} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid xs={12} md={12}>
                                        <h5>Бизнес-процессы</h5>
                                        <DeliveryTable
                                            deliveries={this.state.delivery_states}
                                            showOperator={true}
                                            showAccount={false}
                                            showOpportunity={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <h5>Комментарии</h5>
                            <div className={"card-comments mt-3 bg-white"}>
                                {this.state.comments.length===0?<div className="alert alert-danger">
                                                <i className="icon fas fa-ban"></i> Нет ни одного комментария</div>:null}
                                {this.state.comments.map(
                                    each => {
                                        return (
                                            <CommentItem
                                                comment={each}
                                                readmore={true}
                                                key={'comment-item-'+each.id}
                                            />
                                        )
                                    })}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardOverflow
                    variant='soft'
                    color={this.props.color}
                    sx={{
                        px: 0.2,
                        writingMode: 'vertical-rl',
                        textAlign: 'center',
                        fontSize: 'xs',
                        fontWeight: 'xl',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                        borderLeft: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    {this.props.color}
                </CardOverflow>
            </Card>
        )
    }
}

export default withNavigate(OpportunityCard);
