import React from "react";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Avatar from "@mui/joy/Avatar";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';


// components
import CommentRead from "./CommentRead";
import CommentLike from "./CommentLike";
import CommentFav from "./CommentFav";
import CommentDelete from "./CommentDelete";
import Box from "@mui/joy/Box";
import BaseApi from "../../utils/BaseApi";


class CommentItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: null,
        };
    }

    async componentDidMount() {
        if (this.props.comment.content === undefined) {
            await this.retrieveComment();
        }
        else {
            this.setState({
                comment: this.props.comment
            })
        }
    }

    async retrieveComment() {
        const url = `comment/${this.props.comment.id}/`;
        const params = {};
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                comment: response.data,
            }
        );
    }

    render() {
        if (!this.state.comment){
            return null
        }
        return (
            <ListItem
                key={this.state.comment.id}
                color={this.props.favorite?'warning':'default'}
                variant={'soft'}
                sx={{pl: 1, pr: 1, pt: 2, pb: 1, mr: 2, borderRadius: 'md'}}
            >
                <ListItemDecorator sx={{alignSelf: 'flex-start', mr: 2}}>
                    <Avatar src={process.env.REACT_APP_API_HOST + this.state.comment.owner.avatar} />
                </ListItemDecorator>
                <ListItemContent>
                    <Typography level="body2">
                        <strong>
                            {this.state.comment.owner.first_name} {this.state.comment.owner.last_name}
                        </strong>
                    </Typography>
                    <Typography level="body-sm">
                        {this.state.comment.created_on}
                    </Typography>
                    <Card variant="outlined"
                        sx={{
                            mb: 2,
                            mt: 1,
                            pt: 0,
                            pb: 0,
                        }}
                    >
                        <CardContent>
                            <div dangerouslySetInnerHTML={{__html: this.state.comment.content}} />
                        </CardContent>
                    </Card>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <CommentRead
                            commentID={this.state.comment.id}
                        />
                        <CommentLike
                            liked={this.state.comment.liked}
                            commentID={this.state.comment.id}
                        />
                        <CommentFav
                            comment={this.state.comment}
                            callBack={this.props.callBack}
                        />
                        <CommentDelete
                            comment={this.state.comment}
                            callBack={this.props.callBack}
                        />
                    </Box>
                </ListItemContent>
            </ListItem>
        )
    }
}

export default CommentItem;
