import React from "react";
import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";


class MarketingUserDetailHeader extends React.Component {
    render() {
        return(
            <Stack>
                <Typography level={'h4'}>
                    {this.props.user.first_name} {this.props.user.patronimyc} {this.props.user.last_name}
                </Typography>
                <Typography>
                    {this.props.user.role}, {this.props.user.account_title}
                </Typography>
            </Stack>
        )
    }
}

export default MarketingUserDetailHeader
