import React from 'react';
import {SparkLineChart} from "@mui/x-charts";


class InlineDeliveryStatsBars extends React.Component {

    render() {
        if (!this.props.componentObj.count_values.length || !this.props.componentObj.count_labels.length ){
            return null
        }
        return(
            <SparkLineChart
                plotType="bar"
                data={
                    this.props.componentObj.count_values
                }
                height={40}
                showTooltip
                showHighlight
                xAxis={[
                    {
                        data: this.props.componentObj.count_labels,
                        scaleType: 'band'
                    }
                ]}
            />
        )
    }
}

export default InlineDeliveryStatsBars
