import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";
import Box from '@mui/joy/Box';

// components
import BaseApi from "../../utils/BaseApi";
import {BarChart, PieChart} from "@mui/x-charts";
import Typography from "@mui/joy/Typography";


class HubDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: null,
            visits: null,
        };
    }

    async componentDidMount() {
        await this.retrieveStats();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveStats();
        }
    }

    async retrieveStats() {
        let stats;
        let visits;
        let utms;
        let url;
        let response
        const api = new BaseApi();

        url = 'stat/aggregate/';
        response = await api.get(url, {});
        if (response.status === 200){
            stats = response.data.data
        }

        url = 'visit/aggregate/';
        response = await api.get(url, {});
        if (response.status === 200){
            visits = response.data.data
        }

        url = 'user_utm/aggregate/';
        response = await api.get(url, {});
        if (response.status === 200){
            utms = response.data.data
        }
        this.setState(
            {
                'stats': stats,
                'visits': visits,
                'utms': utms
            }
        );
    }

    render() {
        if (!this.state.stats) {
            return (<CircularProgress/>)
        }
        if (!this.state.visits) {
            return (<CircularProgress/>)
        }
        if (!this.state.utms) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <Typography
                    level={'h3'}
                >
                    Просмотры страниц
                </Typography>
                <BarChart
                    series={[
                        {
                            data: this.state.stats.date_count.map(
                                    (each) => (each.count))
                        },
                    ]}
                    height={290}
                    xAxis={
                        [
                            {
                                data: this.state.stats.date_count.map(
                                    (each) => (each.date)),
                                scaleType: 'band'
                            }
                        ]
                    }
                    margin={{top: 10, bottom: 30, left: 40, right: 10}}
                />
                <Typography
                    level={'h3'}
                >
                    Визиты
                </Typography>
                <BarChart
                    series={[
                        {
                            data: this.state.visits.date_count.map(
                                    (each) => (each.count))
                        },
                    ]}
                    height={290}
                    xAxis={
                        [
                            {
                                data: this.state.visits.date_count.map(
                                    (each) => (each.date)),
                                scaleType: 'band'
                            }
                        ]
                    }
                    margin={{top: 10, bottom: 30, left: 40, right: 10}}
                />
                <Typography
                    level={'h3'}
                >
                    UTM-метки
                </Typography>
                <Stack
                    direction={'row'}
                    sx={{
                        width: '100%'
                    }}
                >
                    <Stack
                        sx={{
                            minWidth: '30%'
                        }}
                    >
                        <Typography
                            level={'h4'}
                        >
                            Source
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: this.state.utms.source.map((each)=>(
                                        {
                                            label: each.source,
                                            value: each.count
                                        }
                                    )),
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 315,
                                    cx: 150,
                                    cy: 150,
                                    highlightScope: {fade: 'global', highlight: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}
                            height={290}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            minWidth: '30%'
                        }}
                    >
                        <Typography
                            level={'h4'}
                        >
                            Medium
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: this.state.utms.medium.map((each)=>(
                                        {
                                            label: each.medium,
                                            value: each.count
                                        }
                                    )),
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 315,
                                    cx: 150,
                                    cy: 150,
                                    highlightScope: {fade: 'global', highlight: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}
                            height={290}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            minWidth: '30%'
                        }}
                    >
                        <Typography
                            level={'h4'}
                        >
                            Campaign
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: this.state.utms.campaign.map((each)=>(
                                        {
                                            label: each.campaign,
                                            value: each.count
                                        }
                                    )),
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 315,
                                    cx: 150,
                                    cy: 150,
                                    highlightScope: {fade: 'global', highlight: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}
                            height={290}
                        />
                    </Stack>
                </Stack>
                <Typography
                    level={'h3'}
                >
                    Технологии
                </Typography>
                <Stack
                    direction={'row'}
                    sx={{
                        width: '100%'
                    }}
                >
                    <Stack
                        sx={{
                            minWidth: '30%'
                        }}
                    >
                        <Typography
                            level={'h4'}
                        >
                            Браузер
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: this.state.stats.browser__name.map((each)=>(
                                        {
                                            label: each.browser__name || 'null',
                                            value: each.count
                                        }
                                    )),
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 315,
                                    cx: 150,
                                    cy: 150,
                                    highlightScope: {fade: 'global', highlight: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}
                            height={290}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            minWidth: '30%'
                        }}
                    >
                        <Typography
                            level={'h4'}
                        >
                            ОС
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: this.state.stats.os__name.map((each)=>(
                                        {
                                            label: each.os__name || 'null',
                                            value: each.count
                                        }
                                    )),
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 315,
                                    cx: 150,
                                    cy: 150,
                                    highlightScope: {fade: 'global', highlight: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}
                            height={290}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            minWidth: '30%'
                        }}
                    >
                        <Typography
                            level={'h4'}
                        >
                            Процессор
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: this.state.stats.cpu__architecture.map((each)=>(
                                        {
                                            label: each.cpu__architecture || 'null',
                                            value: each.count
                                        }
                                    )),
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 315,
                                    cx: 150,
                                    cy: 150,
                                    highlightScope: {fade: 'global', highlight: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}
                            height={290}
                        />
                    </Stack>
                </Stack>
            </Box>
        )
    }
}

export default HubDashboard