import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";


class OpportunityFinancialReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: null,
        };
    }

    async componentDidMount() {
        await this.retrieveStats();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveStats();
        }
    }

    async retrieveStats() {
        const url = `opportunity/financial/?marketing=2&operations=13,11,10,9,8,7,6,5,4,3,1`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                stats: response.data,
            }
        );
    }

    render() {
        if (!this.state.stats) {
            return (<CircularProgress/>)
        }
        return (
            <CommonPaginatedTable
                title={null}
                data={this.state.stats.statistics}
                fields={[
                    {
                        title: 'Сделка',
                        name: 'opportunity.short_name',
                        visible: true,
                        linkPattern: '/opportunity/detail/:linkParam',
                        linkParam: 'opportunity.id',
                    },
                    {
                        title: 'Начало',
                        name: 'start',
                        visible: true,
                    },
                    {
                        title: 'Конец',
                        name: 'end',
                        visible: true,
                    },
                    {
                        title: 'Дельта',
                        name: 'delta_days',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                    },
                    {
                        title: 'Человеко-часы (инж)',
                        name: 'total.workload_fact_estimation',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Оплата труда (инж)',
                        name: 'total.expense_fact_estimation',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('warning'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Операционные',
                        name: 'operations',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('warning'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Операционные (лок.)',
                        name: 'operations_local',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('warning'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Маркетинговые',
                        name: 'marketing',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('warning'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Инженерные',
                        name: 'engineering',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('warning'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Бонус',
                        name: 'bonus',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('warning'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Общие затраты',
                        name: 'expense',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('warning'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Выручка',
                        name: 'revenue',
                        visible: true,
                        chip: true,
                        color_rule: (value, comparison) => ('primary'),
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                    {
                        title: 'Прибыль',
                        name: 'profit',
                        visible: true,
                        chip: true,
                        color_value: 'profit',
                        color_rule: (value, comparison) => {
                            return value > 0?'success':'danger'
                        },
                        preProcess: (value) => (value?value.toFixed(1):value)
                    },
                ]}
            />
        )
    }
}

export default OpportunityFinancialReport