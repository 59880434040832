import React, {} from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import CircularProgress from "@mui/joy/CircularProgress";
import Box from "@mui/joy/Box";
import Badge from "@mui/joy/Badge";
import NotificationsIcon from '@mui/icons-material/Notifications';

// components
import withNavigate from "../../utils/withNavigate";
import BaseApi from "../../utils/BaseApi";


class NotificationButton extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            count: null
        };
    }

    componentDidMount() {
        this.retrieveNotifications();
        let token = JSON.parse(localStorage.getItem("authTokens")).access;
        this.client = new W3CWebSocket(
            `${process.env.REACT_APP_WS_HOST}/ws/notification/?token=${token}`
        );
        this.client.onopen = () => {
            console.log("WebSocket Client Connected");
        };
        this.client.onclose = () => {
            console.log("WebSocket Client Disconnected");
        }
        this.client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
                this.setState({
                    count: dataFromServer.count,
                });
            }
        };
        this.timer = setInterval(() => (this.client.send(JSON.stringify({type: "ping"}))), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    async retrieveNotifications() {
        const api = new BaseApi();
        let response = await api.get(
            'notification/',
            { 'is_read': 0 }
        );

        this.setState(
            {
                count: response.data.count,
            }
        );
    }

    render() {
        if (this.state.count === null || this.state.count === undefined) {
            return (<CircularProgress />)
        }
        return(
            <Box onClick={() => this.props.navigate('/notification')} sx={{'cursor': 'pointer'}}>
                <Badge badgeContent={this.state.count} color="danger">
                    <NotificationsIcon />
                </Badge>
            </Box>
        )
    }
}

export default withNavigate(NotificationButton);
