import React from "react";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import {CircularProgress} from "@mui/joy";


class MarketingUserUTMTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            utms: null,
        };
    }

    async componentDidMount() {
        await this.retrieveUTMs();
    }

    async retrieveUTMs() {
        await this.setState(
            {
                utms: null
            }
        );
        const url = `marketing_user/${this.props.user.id}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                utms: response.data.utms,
            }
        );
    }

    render() {
        if (!this.state.utms) {
            return (<CircularProgress/>)
        }
        return (
            <CommonPaginatedTable
                data={this.state.utms}
                fields={[
                    {
                        title: 'Источник',
                        name: 'source',
                        visible: true,
                    },
                    {
                        title: 'Средство передачи',
                        name: 'medium',
                        visible: true,
                    },
                    {
                        title: 'Кампания',
                        name: 'campaign',
                        visible: true,
                    },
                ]
                }
            />
        )
    }
}

export default MarketingUserUTMTab
